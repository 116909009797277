import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SiteWrapper from 'components/Layout/SiteWrapper/SiteWrapper';
import Modals from 'components/Modal/Modals/Modals';
import {
  clearSiteContent,
  getCurrentUser,
} from 'services/state/actions/userActions';
import { getLsToken } from 'utils';
import Routes from 'router/Router';
import { useFacebookPixel } from 'hooks/useFacebookPixel';

const App = () => {
  const dispatch = useDispatch();
  const { access } = getLsToken();

  // Initialize Facebook Pixel
  useFacebookPixel();

  useEffect(() => {
    if (access) {
      dispatch(getCurrentUser() as any);
    }
    dispatch(clearSiteContent() as any);
  }, [dispatch, access]);

  return (
    <>
      <SiteWrapper>
        <Routes />
      </SiteWrapper>
      <Modals />
    </>
  );
};
export default App;
